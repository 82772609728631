import React, { useEffect, useState, useMemo } from "react";
import { v4 as uuid4 } from "uuid";
import "./App.css";
import HomePage from "./components/HomePage/HomePage";
import LoginPage from "./components/LoginPage/LoginPage";
import Cookies from "js-cookie";

export const App = () => {
  const [loading, setLoading] = useState(true);
  const [kiosk, setKiosk] = useState(false);

  const urlState = useMemo(() => {
    // console.log(window.location.pathname);
    const queryParams = new URLSearchParams(window.location.search);
    const pKey = queryParams.get("key") || Cookies.get("eab-key");
    const coreId = queryParams.get("coreid") || uuid4();
    setKiosk(queryParams.get("kiosk") === "true");
    if (window.location.pathname === "/login") {
      if (queryParams.get("kiosk") === "true") {
        Cookies.set("kiosk", "true");
      } else {
        Cookies.remove("kiosk");
      }
    }

    // console.log('Inside urlState: ', pKey, coreId);
    return { pKey, coreId, pathname: window.location.pathname };
  }, []);

  useEffect(() => {
    if (urlState.pathname !== "/login") {
      if (
        urlState.pKey === null ||
        (typeof urlState.pKey === "string" && urlState.pKey.trim().length < 9)
      ) {
        window.location.href = "/login";
        return;
      }
    }

    setLoading(false);
  }, [urlState]);

  if (loading) return <div>Loading ...</div>;

  return (
    <div className="App">
      {urlState.pathname !== "/login" && (
        <HomePage pKey={urlState.pKey} coreId={urlState.coreId} />
      )}
      {urlState.pathname === "/login" && <LoginPage kiosk={kiosk} />}
    </div>
  );
};
