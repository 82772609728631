import "./LoginPage.css";
import { useState, useCallback, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import footerImg from "../../images/footer-placeholder-base.png";
import Cookies from "js-cookie";

const LoginPage = ({ kiosk }) => {
  const [email, setEmail] = useState(kiosk ? "" : Cookies.get("eab-email") || "");
  const [error, setError] = useState("");
  const url = window.env.API_BASE_URL;
  const { lastJsonMessage, sendJsonMessage, readyState } = useWebSocket(url, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.status === "lookup") {
        Cookies.set("eab-key", lastJsonMessage.result);
        window.location.href = "/?key=" + lastJsonMessage.result;
      } else if (lastJsonMessage.status === "noprofile") {
        setError("Please make sure you are using the same email as your event registration.");
      }
    }
  }, [lastJsonMessage]);

  const handleSubmit = useCallback(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({ cmd: "lookup", email });
      if (!kiosk) Cookies.set("eab-email", email ?? "");
    }
  }, [email, readyState, sendJsonMessage, kiosk]);

  return (
    <div className="root">
      <div className="main-container d-flex align-items-center justify-content-center">
        <div>
          <div>
            {/* <p className="text-center login-heading-text fw-bolder">
              The future of higher education awaits.
            </p> */}
            <h1 className="text-center thank-you-text fw-bolder">
              The future of higher education awaits.
            </h1>

            <p className="text-center heading-text fw-bolder">
              Enter your email to start creating your magazine
            </p>

            <div className="d-flex flex-column align-items-center mb-4">
              <div>
                <input
                  className="input-field"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {!!error && <p className="display-5 mb-4 error-text">{error}</p>}
                <div>
                  <button className="button-style mt-4" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="py-4">
              <img src={footerImg} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
